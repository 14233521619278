#app {
  padding: 1em;
  display: grid;
}

h1 {
  font-size: 4rem;
}

.timer {
  text-align: center;
  padding: 0.5em;
  border: 1px solid black;
  width: 200px;
  border-radius: 6px;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.timer:active {
  transform: translateY(2px);
}

.time {
  padding: 0.5em;
  margin-bottom: 0.5em;
}

button {
  padding: 0.5em 1em;
  border-style: none;
  border-radius: 6px;
  border: 2px solid black;
  background: white;
  cursor: pointer;
}

button:active {
  transform: translateY(2px);
}

.btn-primary.active {
  background-color: white;
}

.rounded-start {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-end {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

#main {
  margin-top: 70px;
}

.negative-value {
  color: #b22c01;
  text-align: center;
  font-size: 18px;
}
.positive-value {
  color: #087223;
  text-align: center;
  font-size: 18px;
}
.normal-value {
  color: #111111;
  text-align: center;
  font-size: 18px;
}
#box {
  margin-top: 30px;
  width: -webkit-fill-available;
  border: solid 0px;
  border-bottom: green solid 2px;
}
#box:focus {
  outline: none;
}
.borderless td,
.borderless th {
  border: none !important;
  background-color: rgba(255, 255, 255, 0.537);
}

table.table {
  border-collapse: collapse;
}
table.table td,
table.table th {
  padding: 1px;
  margin: 0px;
}
#buton {
  color: green;
  font-weight: 500;
}
.table-responsive::-webkit-scrollbar {
  display: none;
}
.table-responsive {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
#mytr td {
  color: red;
}
#myhead td {
  font-weight: 500;
}
#mytbl tr td {
  font-weight: 500;
}

#rowitem {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn-primary.active {
  background-color: white;
}

.rounded-start {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-end {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

#main {
  margin-top: 70px;
}
#headbtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.red-cell {
  background-color: red;
}

.dark-yellow-cell {
  background-color: darkgoldenrod;
}

.light-yellow-cell {
  background-color: lightgoldenrodyellow;
}
.green-cell {
  background-color: rgb(51, 146, 80);
}

.password-input-container {
  position: relative;
  width: 1000px;
}

.password-input-container input {
  padding-right: 30px;
}

.eye-icon-wrapper {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-input-container {
  position: relative;
}

.password-input {
  width: calc(100% - 40px);
  padding-right: 40px;
}

.eye-icon-wrapper {
  position: absolute;
  top: 73%;
  right: 18px;
  transform: translateY(-50%);
  cursor: pointer;
}
.eye-icon-wrapper-1 {
  position: absolute;
  top: 52%;
  right: 12px;
  transform: translateY(-50%);
  cursor: pointer;
}

#usicon {
  font-size: 1.3rem;
}
