
table {
    border-collapse: collapse;
    width: 100%;
  }
  
  tr:nth-child(even) {background-color: #f2f2f2;}
  .top table {
      border-collapse: collapse;
      font-family: sans-serif;
      font-size: 0.8rem;
      letter-spacing: 1px;
      font-size: medium;
    }
   .red
      {
          background-color: #b22c01;
          color: #fff;
          text-align: center;
          
      }
      .white
          {
              background-color: #e0e6e6;   
              text-align: center;                    
          }
  
      .green
      {
          background-color: #10772a;
          color: #fff;
          text-align: center;
      }
      
    th,
    td {
      border: 1px solid #fff;
      background-color: #fff;
      text-align: left;
    padding: 8px;
    }
    
    td:last-of-type {
      text-align: center;
      
    }
    
    tbody {
      background-color: rgb(237 238 242); 
    }
    
    tfoot th {
      text-align: right;
    }
    
    tfoot td {
      font-weight: bold;
    }
  
    .text-right-td{
        font-size: medium;
        background-color: #f4f6f6;
        text-align: center;
      }
    .text-right-td span{
      font-size: x-small;
    }
    .text-center-td{
      font-size: medium;
      background-color: #e0e6e6;
      text-align: center;
    }
    .text-center-td span{
      font-size: x-small;
    }
    .text-left-td{
      font-size: medium;
      background-color: #eef7f5;
      text-align: center;
    }
    .text-left-td span{
      font-size: x-small;
    }
   .text h4{
      width: 100%;
      border: solid 1px #006e1c ;
      padding: 3px;
      text-align: center;
      background-color: #006e1c;
      border-radius: 5PX;
      color: #fff; 
  }
  .center td{
    padding-top: 0px;
  }
  .button{
    padding: 10px;
    margin-left: 500px;
  }
  .container-fluid{
   padding-top: 10px;
  }   
